import { t, Trans } from '@lingui/macro'
import {
  Button,
  Image,
  Input,
  Link,
  TextArea,
  Tooltip,
  useLightBox,
  ValidationContainer,
  Clickable,
} from 'components'
import { QueryLoader, useBankPermissions, useToasts } from 'containers'
import React from 'react'
import {
  BankCredentialsBySlugQuery,
  ToastKind,
  useBankCredentialsBySlugQuery,
  useSyncBankEnvChangesMutation,
} from 'types'
import { getImageUrl } from 'utils/url'
import RetryIcon from 'icons/retry.svg'
import { useAsyncCallback } from 'utils/handleError'
import { GraphQLError } from 'graphql'

interface BankCredentialsFieldsProps {
  credentials: NonNullable<
    BankCredentialsBySlugQuery['bankEnvironment']
  >['credentials']
  envSlug: string
  envName: string
  envId?: string
  canRefresh?: boolean
}

const BankCredentialsFields: React.FC<BankCredentialsFieldsProps> = ({
  credentials,
  envSlug,
  envName,
  envId,
  canRefresh,
}) => {
  const [showPreview, ref] = useLightBox()
  const { addToast } = useToasts()

  const [sync] = useSyncBankEnvChangesMutation()
  const syncChanges = useAsyncCallback(async () => {
    if (!envId) {
      throw 'Invalid environment ID'
    }

    try {
      await sync({ variables: { envId } })
    } catch (ex: any) {
      addToast({
        kind: ToastKind.Error,
        content: t`error.somethingWentWrong.text`,
      })
      return
    }

    addToast({
      kind: ToastKind.Success,
      content: t`bank.environment.credentials.discovery_endpoint_uri.sync.success`,
    })
    setTimeout(() => window.location.reload(), 1000)
  }, [envId, sync])

  return (
    <>
      <div className="flex flex-row items-center my-16">
        <Input
          className="mb-20 lg:my-10"
          labelPosition="left"
          readOnly
          value={credentials?.discovery_endpoint_uri}
          data-cy-id="discovery_endpoint_uri"
          clipboard
          label={
            <Trans id="bank.environment.credentials.discovery_endpoint_uri">
              Discovery Endpoint URI
            </Trans>
          }
          labelTooltip={t`bank.environment.credentials.discovery_endpoint_uri.tooltip`}
        />
        {canRefresh && (
          <Tooltip
            kind="dark"
            displayTip={
              <Trans
                id={'bank.environment.credentials.discovery_endpoint_uri.sync'}
              />
            }
            id="discovery_endpoint_uri.sync"
            className="ml-10"
          >
            <Clickable
              data-cy-id="bank.environment.credentials.discovery_endpoint_uri.sync"
              onClick={() => syncChanges()}
            >
              <RetryIcon />
            </Clickable>
          </Tooltip>
        )}
      </div>

      <ValidationContainer
        label={<Trans id="bank.environment.credentials.logo_id">Logo</Trans>}
        labelPosition="left"
        labelTooltip={t`bank.environment.credentials.logo_id.tooltip`}
      >
        <div className="my-10 w-32 h-32 rounded bg-grey-200 border border-grey-300">
          {credentials?.logo && (
            <Link
              kind="plain"
              onClick={showPreview?.(getImageUrl(credentials?.logo))}
              data-cy-id="bank.environment.credentials.logo.showPreview"
            >
              <Image
                media={credentials.logo}
                size={32}
                className="rounded object-cover"
                alt={t`bank.environment.credentials.logo_id.alt`}
                ref={ref}
              />
            </Link>
          )}
        </div>
      </ValidationContainer>

      <ValidationContainer
        label={
          <Trans id="bank.environment.credentials.name">Environment name</Trans>
        }
        labelPosition="left"
        labelTooltip={t`bank.environment.credentials.name.tooltip`}
        className="py-10"
      >
        <div className="font-mono text-14 leading-relaxed text-primary-dark">
          <div
            className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
            data-cy-id="bank.environment.credentials.name"
          >
            {envName}
          </div>
        </div>
      </ValidationContainer>

      <ValidationContainer
        label={
          <Trans id="bank.environment.credentials.slug">Environment slug</Trans>
        }
        labelPosition="left"
        labelTooltip={t`bank.environment.credentials.slug.tooltip`}
        className="py-10"
      >
        <div className="font-mono text-14 leading-relaxed text-primary-dark">
          <div
            className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
            data-cy-id="bank.environment.credentials.slug"
          >
            {envSlug}
          </div>
        </div>
      </ValidationContainer>

      <TextArea
        className="my-10"
        textAreaClassName="font-mono text-14 leading-22"
        labelPosition="left"
        data-cy-id="bank.environment.credentials.client_id_whitelist"
        label={
          <Trans id="bank.environment.credentials.client_id_whitelist">
            Client ID whitelist
          </Trans>
        }
        labelTooltip={t`bank.environment.credentials.client_id_whitelist.tooltip`}
        value={credentials?.client_id_whitelist.join('\n')}
        lineNumbers
        initialRows={credentials?.client_id_whitelist.length || 1}
        readOnly
      />

      <h3 className="text-18 font-medium mt-38 my-10">
        <Trans id="bank.environment.credentials.endpointUris">
          Endpoint URIs
        </Trans>
      </h3>
      <Input
        className="mb-20 lg:my-10"
        labelPosition="left"
        readOnly
        value={credentials?.issuer_uri}
        data-cy-id="issuer_uri"
        clipboard
        label={
          <Trans id="bank.environment.credentials.issuer_uri">Issuer URI</Trans>
        }
        labelTooltip={t`bank.environment.credentials.issuer_uri.tooltip`}
      />

      <Input
        className="mb-20 lg:my-10"
        labelPosition="left"
        readOnly
        value={credentials?.auth_endpoint_uri}
        data-cy-id="auth_endpoint_uri"
        clipboard
        label={
          <Trans id="bank.environment.credentials.auth_endpoint_uri">
            Authorization endpoint URI
          </Trans>
        }
        labelTooltip={t`bank.environment.credentials.auth_endpoint_uri.tooltip`}
      />
      <Input
        className="mb-20 lg:my-10"
        labelPosition="left"
        readOnly
        value={credentials?.token_endpoint_uri}
        data-cy-id="token_endpoint_uri"
        clipboard
        label={
          <Trans id="bank.environment.credentials.token_endpoint_uri">
            Token endpoint URI
          </Trans>
        }
        labelTooltip={t`bank.environment.credentials.token_endpoint_uri.tooltip`}
      />

      <Input
        className="mb-20 lg:my-10"
        labelPosition="left"
        readOnly
        value={credentials?.userinfo_endpoint_uri}
        data-cy-id="userinfo_endpoint_uri"
        clipboard
        label={
          <Trans id="bank.environment.credentials.userinfo_endpoint_uri">
            User info endpoint URI
          </Trans>
        }
        labelTooltip={t`bank.environment.credentials.userinfo_endpoint_uri.tooltip`}
      />

      <Input
        className="mb-20 lg:my-10"
        labelPosition="left"
        readOnly
        value={credentials?.profile_endpoint_uri}
        data-cy-id="profile_endpoint_uri"
        clipboard
        label={
          <Trans id="bank.environment.credentials.profile_endpoint_uri">
            Profile endpoint URI
          </Trans>
        }
        labelTooltip={t`bank.environment.credentials.profile_endpoint_uri.tooltip`}
      />

      <Input
        className="mb-20 lg:my-10"
        labelPosition="left"
        readOnly
        value={credentials?.ros_endpoint_uri}
        data-cy-id="ros_endpoint_uri"
        clipboard
        label={
          <Trans id="bank.environment.credentials.ros_endpoint_uri">
            Profile endpoint URI
          </Trans>
        }
        labelTooltip={t`bank.environment.credentials.ros_endpoint_uri.tooltip`}
      />

      <Input
        className="mb-20 lg:my-10"
        labelPosition="left"
        readOnly
        value={credentials?.jwks_uri}
        data-cy-id="jwks_uri"
        clipboard
        label={
          <Trans id="bank.environment.credentials.jwks_uri">JWKs URI</Trans>
        }
        labelTooltip={t`bank.environment.credentials.jwks_uri.tooltip`}
      />

      <Input
        className="mb-20 lg:my-10"
        labelPosition="left"
        readOnly
        value={credentials?.registration_endpoint_uri}
        data-cy-id="registration_endpoint_uri"
        clipboard
        label={
          <Trans id="bank.environment.credentials.registration_endpoint_uri">
            Registration endpoint URI
          </Trans>
        }
        labelTooltip={t`bank.environment.credentials.registration_endpoint_uri.tooltip`}
      />

      <Input
        className="mb-20 lg:my-10"
        labelPosition="left"
        readOnly
        value={credentials?.healthcheck_endpoint_uri}
        data-cy-id="healthcheck_endpoint_uri"
        clipboard
        label={
          <Trans id="bank.environment.credentials.healthcheck_endpoint_uri">
            Healthcheck endpoint URI
          </Trans>
        }
        labelTooltip={t`bank.environment.credentials.healthcheck_endpoint_uri.tooltip`}
      />
    </>
  )
}

interface BankCredentialsProps {
  slug: string
  envSlug: string
  envName: string
  envId?: string
}

export const BankCredentials: React.FC<BankCredentialsProps> = ({
  slug,
  envSlug,
  envName,
  envId,
}) => {
  const credentialsQuery = useBankCredentialsBySlugQuery({
    variables: { slug: envSlug },
  })
  const credentials = credentialsQuery.data?.bankEnvironment?.credentials
  const permission = useBankPermissions()

  return (
    <div className="text-16 form-mono-font mb-40">
      <h2 className="heading-h2 mt-40 mb-24">
        <Trans id="bank.environment.credentials.heading">Credentials</Trans>
      </h2>
      <QueryLoader queries={[credentialsQuery]}>
        {credentials && (
          <BankCredentialsFields
            envSlug={envSlug}
            envName={envName}
            credentials={credentials}
            envId={envId}
            canRefresh={permission('ENV:W')}
          />
        )}
        {!credentials && (
          <>
            <p className="text-grey-900">
              <Trans id="bank.environment.credentials.notConfigured">
                Please configure your environment to generate credentials.
              </Trans>
            </p>
            <div className="flex mt-20">
              <Button
                to={`/banks/${slug}/environments/${envSlug}/config`}
                data-cy-id="bank.environment.credentials.configureButton"
                disabled={!permission('ENV:W')}
                tooltip={
                  !permission('ENV:W') && (
                    <Trans id="tooltip.missingWritePermission">
                      You don&apos;t have permission to do this.
                    </Trans>
                  )
                }
              >
                <Trans id="bank.environment.credentials.configureButton">
                  Go to settings
                </Trans>
              </Button>
            </div>
          </>
        )}
      </QueryLoader>
    </div>
  )
}
