import { t, Trans } from '@lingui/macro'
import {
  Breadcrumbs,
  Dropdown,
  DropdownLink,
  Error404,
  Link,
  Logo,
  StatusLight,
} from 'components'
import { QueryLoader } from 'containers/QueryLoader'
import OverviewIcon from 'icons/bar-chart.svg'
import Chevron from 'icons/chevron.svg'
import LegalIcon from 'icons/legal.svg'
import AddIcon from 'icons/plus.svg'
import SettingsIcon from 'icons/settings.svg'
import UsersIcon from 'icons/users.svg'
import { useRouter } from 'next/router'
import React from 'react'
import {
  BankMetaQuery,
  useBankEnvironmentsListQuery,
  useBankMetaQuery,
  useBanksQuery,
  useCurrentUserQuery,
  useUserInBankMetaQuery,
} from 'types'
import { useFeature } from 'utils/features'
import { useIsLoggedIn } from 'utils/withAuth'
import { useBankPermissions, UserInBankContext } from './Can'
import { Layout } from './Layout'
import { Sidebar, SidebarSection } from './Sidebar'

interface BankEnvironmentsListProps {
  slug: string
}

const BankEnvironmentsList: React.FC<BankEnvironmentsListProps> = ({
  slug,
}) => {
  const environmentsQuery = useBankEnvironmentsListQuery({
    variables: { slug },
  })
  // TODO request sorted data
  const envs = environmentsQuery.data?.bankBySlug?.environments
    .slice()
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))

  const hasPermission = useBankPermissions()
  const canRead = hasPermission('ENV:R') || hasPermission('APL:R')
  const location = useRouter()?.asPath

  return (
    <QueryLoader
      queries={[environmentsQuery]}
      errorComponent={
        <Trans id="error.bankEnvironmentsNotLoaded">
          There was an error loading environments.
        </Trans>
      }
      loaderComponent={
        <Trans id="loading.bankEnvironments">Loading environments...</Trans>
      }
    >
      {envs?.map((env) => (
        <Link
          to={`/banks/${slug}/environments/${env.slug}/credentials`}
          kind="sidebar"
          active={new RegExp(`/environments/${env.slug}/`).test(location)}
          key={env.name}
          disabled={!canRead}
          tooltip={
            !canRead && (
              <Trans id="tooltip.missingReadPermission">
                You don&apos;t have permission to see this.
              </Trans>
            )
          }
          tooltipId="bank.missingPermission.ENV:R"
          className="max-w-190"
          data-cy-id={`bankEnvironments.open.${env.slug}`}
        >
          <StatusLight
            kind={env.isActive ? 'success' : 'warning'}
            className="inline-block mr-12 flex-shrink-0"
          />
          <span className="truncate">{env.name}</span>
        </Link>
      ))}
    </QueryLoader>
  )
}

interface BankDetailSidebarProps {
  slug: string
}

const BankDetailSidebar: React.FC<BankDetailSidebarProps> = ({ slug }) => {
  const hasPermission = useBankPermissions()
  const canSeeEnvironments = hasPermission('ENV:R') || hasPermission('APL:R')

  const singleEnvOnly = useFeature('SINGLE_BANK_ENV')
  const enableReports = useFeature('reports')

  return (
    <Sidebar>
      <SidebarSection heading={<Trans id="bank.sidebar.bank">Bank</Trans>}>
        <Link
          to={`/banks/${slug}`}
          kind="sidebar"
          data-cy-id={`bank.sidebar.overview`}
        >
          <OverviewIcon className="inline mr-14" />
          <Trans id="bank.sidebar.overview">Overview</Trans>
        </Link>
      </SidebarSection>

      <SidebarSection
        heading={<Trans id="app.sidebar.environments">Environments</Trans>}
      >
        {canSeeEnvironments && <BankEnvironmentsList slug={slug} />}

        {!singleEnvOnly && (
          <Link
            to={`/banks/${slug}/environments/create`}
            kind="sidebar"
            disabled={!hasPermission('ENV:W')}
            tooltip={
              !hasPermission('ENV:W') && (
                <Trans id="tooltip.missingWritePermission">
                  You don&apos;t have permission to do this.
                </Trans>
              )
            }
            tooltipId="bank.missingPermission.ENV:W"
            data-cy-id="bank.sidebar.createEnvironment"
          >
            <AddIcon className="inline-block mb-4 mr-12 w-20 h-20" />
            <span className="truncate">
              <Trans id="bank.sidebar.environments.create">
                New Environment
              </Trans>
            </span>
          </Link>
        )}
      </SidebarSection>

      <SidebarSection
        heading={
          <Trans id="bank.sidebar.bankManagement">Bank Management</Trans>
        }
      >
        <Link
          to={`/banks/${slug}/config`}
          kind="sidebar"
          disabled={!hasPermission('BNK:R')}
          tooltip={
            !hasPermission('BNK:R') && (
              <Trans id="tooltip.missingWritePermission" />
            )
          }
          tooltipId="app.missingPermission.BNK:W"
          data-cy-id="bank.sidebar.config"
        >
          <SettingsIcon className="inline mr-8" />
          <Trans id="bank.sidebar.config">Bank settings</Trans>
        </Link>

        {enableReports && (
          <Link
            to={`/banks/${slug}/reports`}
            kind="sidebar"
            disabled={!hasPermission('REP:R')}
            tooltip={
              !hasPermission('REP:R') && (
                <Trans id="tooltip.missingReadPermission">
                  You don&apos;t have permission to see this.
                </Trans>
              )
            }
            tooltipId="bank.missingPermission.REP:R"
            data-cy-id="bank.sidebar.reports"
          >
            <LegalIcon className="inline mr-8" />
            <Trans id="bank.sidebar.reports">Reports</Trans>
          </Link>
        )}

        <Link
          to={`/banks/${slug}/users`}
          kind="sidebar"
          disabled={!hasPermission('USR:R')}
          tooltip={
            !hasPermission('USR:R') && (
              <Trans id="tooltip.missingReadPermission">
                You don&apos;t have permission to see this.
              </Trans>
            )
          }
          tooltipId="bank.missingPermission.USR:R"
          data-cy-id="bank.sidebar.users"
        >
          <UsersIcon className="inline mr-8" />
          <Trans id="bank.sidebar.users">Users</Trans>
        </Link>
      </SidebarSection>
    </Sidebar>
  )
}

const BankDropdownMenu: React.FC = () => {
  const location = useRouter()?.asPath

  const banksQuery = useBanksQuery()
  const banks = banksQuery.data?.currentUser?.banks?.edges

  return (
    <ul className="bg-white-100 shadow rounded-lg py-6 mt-10 relative z-10 max-h-half-screen overflow-y-auto">
      <QueryLoader queries={[banksQuery]}>
        {banks?.map(({ node: { bank } }) => (
          <DropdownLink
            to={location
              .replace(/^\/banks\/[^/]+/, `/banks/${bank.slug}`)
              .replace(/environments\/.*/, '')} // remove environment slug
            key={bank.slug}
          >
            <Logo
              media={bank.logo}
              size={24}
              subject="bank"
              className="inline-block mr-6 -mt-4"
            />
            {bank.name}
          </DropdownLink>
        ))}
      </QueryLoader>
    </ul>
  )
}

interface BankDropdownProps {
  selectedBank: NonNullable<BankMetaQuery['bankBySlug']>
}

const BankDropdown: React.FC<BankDropdownProps> = ({ selectedBank }) => (
  <Dropdown
    display={
      <div className="border border-grey-400 rounded-8 flex items-center cursor-pointer p-6 max-w-230">
        <Logo
          media={selectedBank.logo}
          size={24}
          subject="bank"
          className="mr-6"
        />
        <span className="flex-grow text-grey-900 truncate">
          {selectedBank.name}
        </span>
        <Chevron className="inline fill-grey-600" />
      </div>
    }
    displayClassName="inline-block w-230"
    data-cy-id="banks.selectBankDropdown"
  >
    <BankDropdownMenu />
  </Dropdown>
)

interface BankDetailProps {
  slug: string
  userId: string
  selectedBank: NonNullable<BankMetaQuery['bankBySlug']>
}

export const BankDetail: React.FC<BankDetailProps> = ({
  slug,
  userId,
  selectedBank,
  children,
}) => {
  const userInBankQuery = useUserInBankMetaQuery({
    variables: { slug, userId },
  })
  const userInBank = userInBankQuery.data?.userInBank
  const banksQuery = useBanksQuery()
  const banks = banksQuery.data?.currentUser?.banks
    ? banksQuery.data?.currentUser?.banks?.edges
    : []

  return (
    <QueryLoader queries={[userInBankQuery]}>
      <UserInBankContext.Provider value={userInBank}>
        <div className="mb-32 px-24 sm:px-0 flex flex-col sm:flex-row sm:items-end sm:space-x-20 lg:space-x-40">
          {banks?.length > 1 ? (
            <div className="mb-24 sm:mb-0">
              <div className="text-10 uppercase tracking-widest text-grey-600 font-semibold mb-8">
                <Trans id="bank.bankSelector.currentBank">Current bank</Trans>
              </div>
              <BankDropdown selectedBank={selectedBank} />
            </div>
          ) : null}
          <Breadcrumbs
            path={[
              {
                text: <Trans id="dashboard.title">Dashboard</Trans>,
                path: '/dashboard',
              },
              {
                text: selectedBank.name,
                path: `/banks/${slug}`,
              },
            ]}
            className="mb-4"
          />
        </div>
        <div className="bg-white-100 sm:rounded-16 shadow-wider flex flex-col sm:flex-row min-h-1000">
          <BankDetailSidebar slug={slug} />
          <div className="px-20 md:px-40 w-full min-w-0">{children}</div>
        </div>
      </UserInBankContext.Provider>
    </QueryLoader>
  )
}

interface BankDetailLayoutProps {
  slug: string
}

export const BankDetailLayout: React.FC<BankDetailLayoutProps> = ({
  slug,
  children,
}) => {
  const isLoggedIn = useIsLoggedIn()
  const currentUserQuery = useCurrentUserQuery({ skip: !isLoggedIn })
  const user = currentUserQuery.data?.currentUser

  const bankQuery = useBankMetaQuery({ variables: { slug }, skip: !isLoggedIn })
  const bank = bankQuery.data?.bankBySlug

  return (
    <Layout title={t`bank.title`} requireLogin background="grey">
      <QueryLoader queries={[bankQuery, currentUserQuery]}>
        {!bank ? (
          <Error404 />
        ) : (
          <div className="pt-32 pb-50 mx-auto container sm:px-20">
            {user && (
              <BankDetail slug={slug} userId={user.id} selectedBank={bank}>
                {children}
              </BankDetail>
            )}
          </div>
        )}
      </QueryLoader>
    </Layout>
  )
}
