import { Trans } from '@lingui/macro'
import cn from 'classnames'
import { Avatar, Link, PermissionTag, Tooltip } from 'components'
import {
  useAppPermissions,
  useBankPermissions,
  useOrgPermissions,
} from 'containers/Layout'
import { PermissionKind, sortPermissions } from 'containers/Permissions'
import { QueryLoader } from 'containers/QueryLoader'
import compareAsc from 'date-fns/compareAsc'
import PencilEdit from 'icons/pencil-edit.svg'
import PendingMark from 'icons/pending-clock-mark.svg'
import QuestionMark from 'icons/question-mark.svg'
import CheckMark from 'icons/white-check-mark.svg'
import React, { useMemo } from 'react'
import {
  InvitationState,
  UserInAppListItemFragment,
  UserInBankListItemFragment,
  UserInOrgListItemFragment,
  UsersInAppQuery,
  UsersInBankQuery,
  UsersInOrgQuery,
  useUsersInAppQuery,
  useUsersInBankQuery,
  useUsersInOrgQuery,
} from 'types'
import { makeFullName } from 'utils/utils'
import { useAuthContext } from 'utils/withAuth'
import { ResendInvitationButton } from './ResendInvitationButton'

interface UserListItemProps {
  node:
    | UserInAppListItemFragment
    | UserInBankListItemFragment
    | UserInOrgListItemFragment
  slug: string
  isMe: boolean
}

const UserListItem: React.FC<UserListItemProps> = ({ node, slug, isMe }) => {
  const fullName = makeFullName(node.user.firstName, node.user.lastName)
  const type =
    node.__typename === 'UserInApp'
      ? 'app'
      : node.__typename === 'UserInBank'
      ? 'bank'
      : 'organization'

  const hasAppPermission = useAppPermissions()
  const hasBankPermission = useBankPermissions()
  const hasOrgPermission = useOrgPermissions()
  const hasPermission =
    type === 'app'
      ? hasAppPermission
      : type === 'bank'
      ? hasBankPermission
      : hasOrgPermission

  const permissions = useMemo(
    () =>
      node.permissions
        .filter((item) => !item.includes('PRD')) // Exclude unused PRD permissions
        .slice()
        .sort(sortPermissions(type)),
    [node.permissions, type]
  )

  const invitationPending =
    node.invitation?.state === InvitationState.Pending ||
    node.invitation?.state === InvitationState.Reminded

  const invitationExpired =
    node.invitation?.state === InvitationState.Expired ||
    node.invitation?.state === InvitationState.Seen

  return (
    <div className="flex flex-col xl:flex-row justify-between items-start xl:items-center p-14 xl:px-0 xl:py-14 bg-white-100 odd:bg-grey-100">
      <div className="flex flex-3 xl:flex-2 my-8 xl:my-0 items-center min-w-0 max-w-full">
        <Avatar
          media={node.user.avatar}
          size={40}
          alt={fullName + ' avatar'}
          className="mr-16 xl:mx-16"
        />
        <div className="flex flex-col min-w-0">
          <div className="flex items-center">
            <span
              className={cn(
                'font-medium mr-8',
                node.invitation && 'text-grey-600'
              )}
            >
              {node.invitation ? (
                <div className="flex flex-col sm:flex-row sm:space-x-10">
                  <div>
                    {invitationPending && (
                      <Trans id="users.pendingInvitation">
                        Pending invitation
                      </Trans>
                    )}
                    {invitationExpired && (
                      <span className="text-red-400 font-semibold">
                        <Trans id="users.expiredInvitation">
                          The invitation has expired
                        </Trans>
                      </span>
                    )}
                  </div>
                  {hasPermission('USR:W') && (
                    <ResendInvitationButton invitationId={node.invitation.id} />
                  )}
                </div>
              ) : (
                <>
                  {!node.user.isDeleted ? (
                    fullName
                  ) : (
                    <Trans id="users.anonymized">Anonymized</Trans>
                  )}
                </>
              )}
            </span>
            {isMe && (
              <Tooltip
                kind="dark"
                id="app.users.me"
                displayTip={
                  <Trans id="app.users.me.tooltip">
                    This is my user profile
                  </Trans>
                }
                className="-mt-4 mr-4"
              >
                <span className="bg-grey-800 text-white-100 rounded-full p-4 text-10 flex-shrink-0 whitespace-no-wrap">
                  <Trans id="app.users.me">It&apos;s me</Trans>
                </span>
              </Tooltip>
            )}
          </div>
          <div className="flex flex-shrink-0 flex-grow-0 items-center mr-16 truncate">
            {!node.user.isDeleted ? (
              <>
                <a
                  className="text-primary mr-4 truncate"
                  href={`mailto:${node.user.email}`}
                >
                  {node.user.email}
                </a>

                {node.user.emailVerified ? (
                  <Tooltip
                    kind="dark"
                    id="app.users.emailVerified"
                    displayTip={
                      <Trans id="app.users.emailVerified">
                        This e-mail has been verified.
                      </Trans>
                    }
                    className="-mt-4"
                  >
                    <CheckMark className="inline" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    kind="dark"
                    id="app.users.emailVerificationPending"
                    displayTip={
                      <Trans id="app.users.emailVerificationPending">
                        Verification of this e-mail is pending.
                      </Trans>
                    }
                    className="-mt-4"
                  >
                    <PendingMark className="inline" />
                  </Tooltip>
                )}
              </>
            ) : (
              <p>
                <Trans id="users.deleted">
                  This user account has been deleted
                </Trans>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="w-full xl:w-auto flex items-end xl:items-center flex-4 xl:flex-3 justify-between">
        <div className="flex flex-wrap">
          {permissions.map((perm) => (
            <PermissionTag type={type} key={perm}>
              {perm}
            </PermissionTag>
          ))}
        </div>
        {!node.user.isDeleted && (
          <>
            <Link
              to={`/${type}s/${slug}/users/detail/${node.user.id}`}
              data-cy-id={`user-detail-modal-${node.user.email}`}
              disabled={!hasPermission('USR:W')}
              tooltip={
                !hasPermission('USR:W') && (
                  <Trans id="tooltip.missingWritePermission">
                    You don&apos;t have permission to do this.
                  </Trans>
                )
              }
              tooltipId="userList.missingPermission.USR:W"
              scroll={false}
            >
              <PencilEdit
                width={16}
                height={16}
                className="fill-grey-600 mb-6 lg:mb-0 mx-8 lg:mx-24 flex-shrink-0"
              />
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

interface UserListProps {
  type: PermissionKind
  slug: string
}

type AllUsers =
  | NonNullable<UsersInAppQuery['appBySlug']>['users']['edges'][0]['node']
  | NonNullable<UsersInBankQuery['bankBySlug']>['users']['edges'][0]['node']
  | NonNullable<UsersInOrgQuery['organisationBySlug']>['users'][0]

export const UserList: React.FC<UserListProps> = ({ type, slug }) => {
  const usersInAppQuery = useUsersInAppQuery({
    variables: { slug },
    skip: type !== 'app',
  })
  const usersInBankQuery = useUsersInBankQuery({
    variables: { slug },
    skip: type !== 'bank',
  })
  const usersInOrgQuery = useUsersInOrgQuery({
    variables: { slug },
    skip: type !== 'organization',
  })

  const appUsers = usersInAppQuery.data?.appBySlug?.users.edges.map(
    (edge) => edge.node
  )
  const bankUsers = usersInBankQuery.data?.bankBySlug?.users.edges.map(
    (edge) => edge.node
  )
  const orgUsers = usersInOrgQuery.data?.organisationBySlug?.users
  const allUsers: AllUsers[] | undefined = appUsers ?? bankUsers ?? orgUsers

  // sort users list
  const loggedInUserEmail = useAuthContext().tokens?.accessToken?.parsed.email
  const currentUsers =
    allUsers?.filter((user) => user.user.email === loggedInUserEmail) ?? []

  // TODO sort active users by date created
  const accepted = allUsers?.filter((user) => !user.invitation) ?? []

  const pending =
    allUsers
      ?.filter((user) => !!user.invitation)
      .sort((a, b) =>
        compareAsc(
          new Date(a.invitation?.createdAt ?? ''),
          new Date(b.invitation?.createdAt ?? '')
        )
      ) ?? []

  // current user first, then owner, accepted users and pending invitations
  const users = new Set([...currentUsers, ...accepted, ...pending])

  return (
    <div className="flex flex-col">
      <div className="hidden lg:flex border-b border-grey-300 py-8 text-grey-800">
        <div className="flex flex-3 xl:flex-2">
          <div className="w-72"></div>
          <span>
            <Trans id="users.users">Users</Trans>
          </span>
        </div>
        <div className="flex-4 xl:flex-3">
          <Tooltip
            id="permissions"
            kind="dark"
            className="inline"
            displayTip={
              <Trans id="users.permissions.tooltip">
                Hover over a permission tag to see a description
              </Trans>
            }
          >
            <span className="flex-4 xl:flex-3 mr-1">
              <Trans id="users.permissions">Permissions</Trans>
              <QuestionMark
                width={16}
                height={16}
                className="mx-8 inline"
                data-tip
              />
            </span>
          </Tooltip>
        </div>
      </div>
      <QueryLoader
        queries={[
          type === 'app' && usersInAppQuery,
          type === 'bank' && usersInBankQuery,
          type === 'organization' && usersInOrgQuery,
        ]}
      >
        <>
          {[...users?.values()].map((node) => (
            <UserListItem
              key={node.id}
              node={node}
              slug={slug}
              isMe={node.user.email === loggedInUserEmail}
            />
          ))}
        </>
      </QueryLoader>
    </div>
  )
}
